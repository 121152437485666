<template>
  <div v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-body">
          <div class="my-3">
            <LoadingMessage :message="message"></LoadingMessage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingModal',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    finish() {
      this.$emit('finish');
    },
  },
};
</script>
